import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

export const pageQuery = graphql`
  query {
    conditions: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/conditions_fr/**/*.md" } }
      sort: { order: ASC, fields: [frontmatter___ordre] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

const TermsMD = ({ data }) => {
  const { edges: posts } = data.conditions;
  return (
    <Layout location={typeof window !== 'undefined' ? location : null}>
      <SEO
        title="Conditions d'utilisation - Cinélangues"
        description="Nos conditions légales"
        pathname="/terms/"
      />
      <div className="page">
        {posts.map(({ node: post }) => (
          <div key={post.id}>
            <div className="container jumbotron">
              <div className="row">
                <div className="col-lg-6 header-content mb-1 ml-2">
                  <h1 className="my-auto">
                    <h1>{post.frontmatter.title}</h1>
                  </h1>
                </div>
              </div>
            </div>
            <div className="container content">
              <div className="row">
                <div className="row">
                  <div className="card col-lg-12 p-md-3">
                    <div className="card-body">
                      <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default TermsMD;
